import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../providers/AuthContext";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Select,
  Menu
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PatientDrawerComponent from "../../components/PatientDrawerComponent";
import axios from "axios";
import LanguageIcon from "@mui/icons-material/Language";
import jsPDF from "jspdf";
import PDFForm from "../../components/PDFForm";
import logoImage from "../../assets/dicoptProLogo.png";
import { Modal, Typography, CircularProgress } from "@mui/material";
import CampimetryPDF from "../../components/PDFdocuments/CampimetryPDF";

import { useTranslation } from 'react-i18next';

// Custom styling for the Select component
const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    paddingLeft: "8px",
    paddingRight: "24px", // Make room for the dropdown arrow
    color: "black", // Change text color to black
    "&:focus": {
      backgroundColor: "transparent", // Removes the background color on focus
    },
  },
  "& .MuiSvgIcon-root": {
    color: "black", // Change arrow icon color to black
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none", // Hide underline by default
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none", // Hide underline on hover
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none", // Hide underline when focused
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove border for Outlined variant
  },
  "&:hover": {
    ".MuiOutlinedInput-notchedOutline": {
      border: "none", // Ensure no border on hover for Outlined variant
    },
  },
  "&& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input": {
    padding: "10px 32px 10px 12px", // Adjust padding for Outlined variant to align text properly
  },
});

export default function Patient() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [patientSelected, setPatientSelected] = useState(uid);
  const [patientsList, setPatientsList] = useState([]);
  const [patientIndex, setPatientIndex] = useState(0);
  const [isPDFPopupOpen, setisPDFPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, currentUser } = useContext(AuthContext);
  const [anchorLang, setAnchorLagn] = useState(null);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [loading, setLoading] = useState(true);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [campimetryData, setCampimetryData] = useState(null);

  const { t, i18n } = useTranslation();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const reportTemplateRef = useRef(null);

  useEffect(() => {

    const userId = currentUser.uid;
    const fetchData = async () => {
      try {
        // Realizar la petición a axios para comprobar los datos necesarios
        const response = await axios.get(`${SERVER_URL}/server/patients/${userId}`);

        const patientsArray = Object.keys(response.data).map((key) => ({
          uid: key,
          ...response.data[key],
        }));

        // finally check if the id is in the list of patients
        const uidChecked = patientsArray.some((patient) => patient.uid === uid);

        // Si la comprobación es exitosa, permitir el acceso a la página web
        if (uidChecked) {
          // Si la comprobación es exitosa, continuar con la renderización
          setPatientsList(patientsArray);
          setPatientSelected(uid);
          setPatientIndex(patientsArray.findIndex((patient) => patient.uid === uid));
          setLoading(false);
        } else {
          navigate('/unauthorized'); // Redirigir a una página de error de autorización
        }
      } catch (error) {
        console.error('Error en la comprobación:', error);
        // En caso de error, redirigir a una página de error o a donde necesites
        navigate('/error');
      }
    };

    // Llamar a la función fetchData cuando el componente se monta
    fetchData();
  }, [currentUser, navigate, uid]); // Dependencia navigate para asegurarnos de que tenemos la versión más reciente del objeto navigate


  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,

      });
      // Save the PDF
      pdf.html(reportTemplateRef.current, {
        callback: function (doc) {
          if (!campimetryData.puntos.error) {
            doc.addImage(campimetryData.puntos.img_src, "PNG", 120, 30, 75, 77, "puntos", "FAST");
          }
          if (!campimetryData.valores.error) {
            doc.addImage(campimetryData.valores.img_src, "PNG", 120, 110, 80, 84, "valores", "FAST");
          }
          if (!campimetryData.valores_cruz.error) {
            doc.addImage(campimetryData.valores_cruz.img_src, "PNG", 10, 115, 45, 49, "cruz", "FAST");
          }
          if (!campimetryData.valores_equis.error) {
            doc.addImage(campimetryData.valores_equis.img_src, "PNG", 65, 115, 45, 49, "equis", "FAST");
          }
          if (!campimetryData.interpolados_absoluto.error) {
            doc.addImage(campimetryData.interpolados_absoluto.img_src, "PNG", 10, 200, 85, 70, "absoluto", "FAST");
          }
          if (!campimetryData.interpolados_relativo.error) {
            doc.addImage(campimetryData.interpolados_relativo.img_src, "PNG", 110, 200, 85, 70, "relativo", "FAST");
          }
          doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
          doc.save(`Informe_${patientSelected}.pdf`);
        },
        x: 0,
        y: 0,
        width: 210, // Ancho del contenido en mm
        windowWidth: 210 * 3.75 // Ancho de la ventana en px
      });

      setLetGeneratePDF(false);
    }

  }, [letGeneratePDF]);

  const handlePatientChange = (event) => {
    const selectedUid = event.target.value;
    navigate(`/patient/${selectedUid}`); // Update URL to reflect new selection, if desired
  };

  // function to check if an id is in the list of patients
  const isPatientInList = (id) => {
    // first check if the id is null or undefined
    if (!id) return false;
    // then if the list of patients is empty
    if (!patientsList) return false;
    // finally check if the id is in the list of patients
    return patientsList.some((patient) => patient.uid === id);
  };

  const handleOpenPopup = () => {
    setisPDFPopupOpen(true);
  };

  const handleClosePopup = () => {
    setisPDFPopupOpen(false);
  };

  const handleLangMenuOpen = (event) => {
    setAnchorLagn(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setAnchorLagn(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose(); // Close the menu after logout
    navigate("/"); // Redirect to login page after logout using navigate
  };

  const fetchCampimetryDataDirectly = async () => {

    const requestData = {
      uid: currentUser.uid,
      paciente: patientSelected,
    };

    try {
      const response = await axios.post(
        `${SERVER_URL}/server/campimetry-data`,
        requestData
      );
      // Return the data directly
      return response.data;
    } catch (error) {
      console.error("Error fetching campimetry data:", error);
      throw error;
    }
  };

  const generatePDF = async (formData) => {
    try {
      setIsGeneratingPDF(true);
      setFormData(formData);
      setPatientData(patientsList[patientIndex]);
      const campimetryDataTemp = await fetchCampimetryDataDirectly();
      setCampimetryData(campimetryDataTemp);
      setLetGeneratePDF(true);
      setIsGeneratingPDF(false);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      setIsGeneratingPDF(false);
    }
  };

  const handleRealizarPrueba = (formData) => {
    generatePDF(formData);
  };

  const handleLocale = (selectedLocale) => {
    setAnchorLagn(null); // Close language menu
    i18n.changeLanguage(selectedLocale);
  }

  // Renderizar un mensaje de carga mientras se verifica la autorización
  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      {letGeneratePDF ?
        <div ref={reportTemplateRef}>
          {CampimetryPDF({
            patientData: patientData,
            formData: formData,
            campimetryData: campimetryData
          })}
        </div>
        : null
      }
      <Modal
        open={isGeneratingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfLocale.pdfModalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfLocale.pdfModalDescription')}
          </Typography>
        </Box>
      </Modal>
      <Drawer
        anchor={"left"}
        variant="permanent"
        sx={{ width: 250, overflow: "hidden" }}
        ModalProps={{
          BackdropProps: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          },
        }}
      >
        <PatientDrawerComponent
          handleOpenPopup={handleOpenPopup}
        />
      </Drawer>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          color: "#041e49",
          backgroundColor: "#a4c5fb",
          zIndex: (theme) => theme.zIndex.drawer - 1,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 0.3 }} />
          <CustomSelect
            displayEmpty
            value={patientSelected}
            onChange={handlePatientChange}
            inputProps={{ "aria-label": "Without label" }}
          >
            {patientsList.map((patient, index) => (
              <MenuItem key={index} value={patient.uid} data-index={index}>
                {patient.name}
              </MenuItem>
            ))}
          </CustomSelect>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit" onClick={handleLangMenuOpen}>
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorLang}
            open={Boolean(anchorLang)}
            onClose={handleLangMenuClose}
          >
            <MenuItem onClick={() => handleLocale("en")}>
              {t('english')}
            </MenuItem>
            <MenuItem onClick={() => handleLocale("es")}>
              {t('spanish')}
            </MenuItem>
          </Menu>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>{t('formsLocale.logOut')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          marginLeft: `${250}px`,
          marginTop: 8,
          padding: 3,
          height: "100%",
        }}
      >
        <Outlet />
      </Box>
      <PDFForm
        open={isPDFPopupOpen}
        onClose={handleClosePopup}
        onSubmit={handleRealizarPrueba}
      ></PDFForm>
    </React.Fragment>
  );
}

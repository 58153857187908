import { auth } from "../firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, initializeUser);
    return unsuscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }
    setLoading(false);
  }

  const login = (userData) => {
    // Logic to authenticate user
    setCurrentUser(userData);
    setUserLoggedIn(true);
  };

  const logout = () => {
    // Logic to log out user
    setCurrentUser(null);
    setUserLoggedIn(false);

    try{
      signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }

  };

  const value = {
    currentUser,
    userLoggedIn,
    loading,
    login,
    logout
  }

  return (
    <AuthContext.Provider value={ value }>
      {!loading && children}
    </AuthContext.Provider>
  );
};

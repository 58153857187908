import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { AuthContext } from "../../providers/AuthContext";
import {
  Divider,
  Box,
  Grid,
  Card,
  CardActionArea,
  Typography,
  CardContent,
  CircularProgress,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DashboardAppBar from "../../components/DashboardAppBar";
import axios from "axios";
import AddPatientForm from "../../components/AddPatientForm";

export default function Dashboard() {
  const [patients, setPatients] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { userLoggedIn, currentUser } = useContext(AuthContext);
  const [showAddForm, setShowAddForm] = useState(false);

  const { t } = useTranslation();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  React.useEffect(() => {
    if (!userLoggedIn) return;

    setIsLoading(true);
    const userId = currentUser.uid;
    
    axios
      .get(`${SERVER_URL}/server/patients/${userId}`)
      .then((response) => {
        let patientsArray = Object.keys(response.data).map((key) => ({
          uid: key,
          ...response.data[key],
        }));

        // Sort patients by surname in ascending order
        patientsArray.sort((a, b) => {
          if (a.surname < b.surname) return -1;
          if (a.surname > b.surname) return 1;
          return 0;
        });

        setPatients(patientsArray);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the patient data:", error);
        setIsLoading(false);
      });
  }, [currentUser]);

  const handleAddProject = () => {
    setShowAddForm(true);
  };

  const handleAddPatient = async (patientData) => {
    const userId = currentUser.uid;

    try {
      const response = await axios.post(
        `${SERVER_URL}/server/patients/${userId}`,
        patientData
      );
      console.log("Patient added successfully:", response.data);
      setShowAddForm(false); // Hide the form after successful submission

      // Fetch updated patient data
      setIsLoading(true); // Set loading state to true
      axios
        .get(`${SERVER_URL}/server/patients/${userId}`)
        .then((response) => {
          const patientsArray = Object.keys(response.data).map((key) => ({
            uid: key,
            ...response.data[key],
          }));
          setPatients(patientsArray);
          setIsLoading(false); // Set loading state to false
        })
        .catch((error) => {
          console.error("There was an error fetching the patient data:", error);
          setIsLoading(false); // Set loading state to false
        });
    } catch (error) {
      console.error("Error adding patient:", error);
      // Handle error here (e.g., show error message to the user)
    }
  };

  const handleNavigate = (uid) => {
    navigate(`/patient/${uid}`);
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <DashboardAppBar />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ mt: "-200px", px: 15, py: 7.5 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#01579b",
              }}
            >
              <CardActionArea
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
                onClick={handleAddProject}
              >
                <AddCircleOutlineIcon fontSize="large" />
                <Typography sx={{ marginTop: "10px" }}>
                  {t('addPatient')}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
          {showAddForm && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AddPatientForm onSubmit={handleAddPatient} />
            </Grid>
          )}
          {patients.map((patient, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{ height: 200, display: "flex", flexDirection: "column" }}
              >
                <CardActionArea
                  sx={{ flex: "1 0 auto" }}
                  onClick={() => handleNavigate(patient.uid)}
                >
                  <CardContent sx={{ paddingBottom: "0 !important" }}>
                    <Typography variant="h6" sx={{ mb: 1, color: "#041e49" }}>
                      {patient.surname}, {patient.name}
                    </Typography>
                    <Typography sx={{ color: "#65728b" }} variant="body2">
                      {patient.birthDate}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Divider />
    </Box>
  );
}

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { createTheme, ThemeProvider, Typography, Box, Divider, Grid } from "@mui/material";

const theme = createTheme({
  typography: {
    body2: {
      fontSize: '0.7rem', // Ajusta el tamaño de la letra aquí
    },
  },
});

const CampimetryPDF = ({
  patientData,
  formData,
  campimetryData
}) => {

  const name = patientData.name + " " + patientData.surname;
  const birthDate = patientData.birthDate;

  const gender = formData.gender;
  const leftEyeVA = formData.leftEye;
  const rightEyeVA = formData.rightEye;
  const glasses = formData.glasses ? "Sí" : "No";

  let auxEyeValue = "No especificado";
  switch (campimetryData.eye_value) {
    case 0:
      auxEyeValue = "Izquierdo";
      break;
    case 1:
      auxEyeValue = "Derecho";
      break;
    case 2:
      auxEyeValue = "Ambos";
      break;
    default:
      auxEyeValue = "No especificado";
      break;
  }
  const eyeValue = auxEyeValue;

  let testDateAux = campimetryData.date_value;

  const minutes = Math.floor(campimetryData.total_time / 60);
  const remainingSeconds = Math.floor(campimetryData.total_time % 60); // Truncate to integer
  const timeTest = `${minutes}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;

  let oldData = false;
  let sectionsDoneAux = [];
  let totalFakePositivesAux = [];
  let totalFakeNegativesAux = [];

  // vamos a comprobar primero si los datos son de la funcion vieja
  if (campimetryData.section_done === undefined) {
    oldData = true;
    // testDateAux is in format "YYYYMMDD-HHMMSS" and we want to show it as "DD/MM/YYYY - HH:MM:SS"
    testDateAux = testDateAux.slice(6, 8) + "/" + testDateAux.slice(4, 6) + "/" + testDateAux.slice(0, 4) + " - " + testDateAux.slice(9, 11) + ":" + testDateAux.slice(11, 13) + ":" + testDateAux.slice(13, 15);
  }
  else {
    oldData = false;
    sectionsDoneAux = campimetryData.section_done;
    totalFakePositivesAux = campimetryData.total_fake_positives;
    totalFakeNegativesAux = campimetryData.total_fake_negatives;
  }
  const testDate = testDateAux;

  // these variables are arrays of values, where each value represents a different section of the campimetry:
  // 0: central
  // 1: left
  // 2: right
  // 3: up
  // 4: down
  // array with the sections done
  const sectionsDone = sectionsDoneAux;
  // array with contrast pattern in each section
  const contrastPattern = campimetryData.contrast_pattern;
  // array with the eye fixation in each section
  const eyeFixation = campimetryData.eye_fixation_sensitivity;
  // array of fake positives in each section
  const fakePositives = campimetryData.fake_positives;
  // array of total fake positives in each section
  const totalFakePositives = totalFakePositivesAux;
  // array of fake negatives in each section
  const fakeNegatives = campimetryData.fake_negatives;
  // array of total fake negatives in each section
  const totalFakeNegatives = totalFakeNegativesAux;

  const titleColumns = ["", "Central", "Der.", "Izq.", "Abajo", "Arriba"];
  const tableFontSize = 9;
  const borderSize = 0;

  const renderTable = (isOldData) => {
    if (isOldData) {
      return (
        <ThemeProvider theme={theme}>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>Estrategia:</strong> {contrastPattern}
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>Sensibilidad Eye Tracking:</strong> {eyeFixation}&deg;
          </Typography>
          <Typography variant="body2" sx={{ pl: 4 }}>
            <strong>Falsos positivos:</strong> {fakePositives}
          </Typography>
          {
            contrastPattern === "0-1" ?
            null :
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>Falsos negativos:</strong> {fakeNegatives}
            </Typography>
          }
        </ThemeProvider>
      );
    }
    return (
      <Box sx={{ p: 2, pr: 0 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 50 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                {titleColumns.map((title) => (
                  <TableCell sx={{ fontSize: tableFontSize }}>{title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>Estrategia</TableCell>
                {contrastPattern.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center', whiteSpace: 'nowrap' }}>{sectionsDone[index] && value !== "" ? value : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>Sensibilidad Eye Tracking</TableCell>
                {eyeFixation.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{sectionsDone[index] && value !== -1 ? value+'\u00B0' : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell key="title" sx={{ fontSize: tableFontSize }}>Falsos positivos</TableCell>
                {fakePositives.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{sectionsDone[index] && totalFakePositives[index] !== 0 ? value+'/'+totalFakePositives[index] : '-'}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell  key="title" sx={{ fontSize: tableFontSize }}>Falsos negativos</TableCell>
                {fakeNegatives.map((value, index) => (
                  <TableCell key={index} sx={{ fontSize: tableFontSize, textAlign: 'center' }}>{sectionsDone[index] && totalFakeNegatives[index] !== 0 ? value+'/'+totalFakeNegatives[index] : '-'}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // set size of div to A4 size: 210mm x 297mm
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Nombre:</strong> {name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Fecha de nacimiento:</strong> {birthDate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Sexo:</strong> {gender}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Fecha de prueba:</strong> {testDate}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      <Divider variant="middle" sx={{ mb: 2 }}></Divider>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ThemeProvider theme={theme}>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>Prueba:</strong> Campimetría
            </Typography>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>Ojo:</strong> {eyeValue}
            </Typography>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>Tiempo total de la prueba:</strong> {timeTest}
            </Typography>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>AV ojo derecho:</strong> {rightEyeVA}
            </Typography>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>AV ojo Izquierdo:</strong> {leftEyeVA}
            </Typography>
            <Typography variant="body2" sx={{ pl: 4 }}>
              <strong>Gafas:</strong> {glasses}
            </Typography>
          </ThemeProvider>
          {renderTable(oldData)}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 280, minHeight: 280, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ minWidth: 200, minHeight: 50, border: borderSize, borderColor: 'grey.500', borderRadius: 1 }}
          />
        </Grid>
        <ThemeProvider theme={theme}>
          <Grid item xs={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ pr: 5 }}>
              V-VISIÓN® | La digitalización de la oftalmología y optometría
            </Typography>
          </Grid>
        </ThemeProvider>
      </Grid>

    </div>
  );
};

export default CampimetryPDF;

import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";

import { Stage, Layer, Circle, Line, Text } from "react-konva";

import { ref, child, push, update } from "firebase/database";
import { database } from "../../firebaseConfig";

// ICONS
import InfoIcon from '@mui/icons-material/Info';

import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

import StartTestButton from "../../components/buttons/StartTestButton";

const MainGridScreen = ({
  title,
  server_route,
  test_type,
  stimulus_name
}) => {
  const { currentUser } = useContext(AuthContext);
  const { uid } = useParams();

  const [eye, setEye] = useState("");
  const [stimulusType, setStimulusType] = useState("punctual");
  const [hessLancasterStatus, setHessLancasterStatus] = React.useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isShowingValues, setIsShowingValues] = useState(false);

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    fetchHessLancasterStatus(); // Fetch immediately on mount
    const intervalId = setInterval(fetchHessLancasterStatus, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentUser.uid]); // Depend on uid so if it changes, we re-fetch

  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchHessLancasterStatus = async () => {
    const userId = currentUser.uid;

    axios
      .get(`${SERVER_URL}/server/${server_route}/${userId}`)
      .then((response) => {
        setHessLancasterStatus(response.data);
        //console.log("Hess Lancaster status fetched successfully", response.data.left_eye_pos);
      })
      .catch((error) => {
        console.error("There was an error fetching the patient data:", error);
      });
  };

  const renderMainDots = () => {
    const dots = [];
    const originalXPos = [121, 121, 121, 226, 226, 226, 331, 331, 331];
    const originalYPos = [121, 226, 331, 121, 226, 331, 121, 226, 331];

    for (let i = 0; i < 9; i++) {
      dots.push(
        <Circle
          radius={2}
          x={originalXPos[i]}
          y={originalYPos[i]}
          stroke="black"
          strokeWidth={3}
        />
      );
    }

    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderLeftHessLancasterLines = (isLeft) => {
    const ratio = 333.33;
    const base = 226;
    // if isLeft is true, set base_color with green (#5DD55D) value, otherwise set base_color with red (#E62E1B) value
    const base_color = isLeft ? "#5DD55D" : "#E62E1B";
    const main_color = isLeft ? "green" : "red";
    const base_width = 2;

    // check if hessLancasterStatus is null, if so, return null
    if (!hessLancasterStatus) {
      return null;
    }

    if (hessLancasterStatus?.actual_user !== uid) {
      return null;
    }
    // from response.data.left_eye_pos, get the number of values of the array
    // if isLeft is true, get the left eye values, otherwise get the right eye values
    const actualStimulusType = hessLancasterStatus?.status_hess_lancaster_stimulus_type || "punctual";
    let eyePosAux = [];
    let eyeRotAux = [];
    let actualPointAux = 0;
    if (actualStimulusType === "vertical") {
      eyePosAux = isLeft ? hessLancasterStatus?.left_eye_pos_vertical : hessLancasterStatus?.right_eye_pos_vertical;
      eyeRotAux = isLeft ? hessLancasterStatus?.left_eye_rot_vertical : hessLancasterStatus?.right_eye_rot_vertical;
      actualPointAux = isLeft ? hessLancasterStatus?.actual_point_left_vertical : hessLancasterStatus?.actual_point_right_vertical;
    }
    if (actualStimulusType === "punctual") {
      eyePosAux = isLeft ? hessLancasterStatus?.left_eye_pos_punctual : hessLancasterStatus?.right_eye_pos_punctual;
      eyeRotAux = isLeft ? hessLancasterStatus?.left_eye_rot_punctual : hessLancasterStatus?.right_eye_rot_punctual;
      actualPointAux = isLeft ? hessLancasterStatus?.actual_point_left_punctual : hessLancasterStatus?.actual_point_right_punctual;
    }

    const eyePos = eyePosAux;
    const eyeRot = eyeRotAux;
    const actualPoint = actualPointAux;


    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    // loop to create the rest of the lines and store them in an array
    const lines = [];
    for (let i = 1; i < actualPoint - 1; i++) {
      lines.push(
        <Line
          points={[
            base + eyePos[i].x * ratio,
            base - eyePos[i].y * ratio,
            base + eyePos[i + 1].x * ratio,
            base - eyePos[i + 1].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    }

    // there are some extra lines that are needed to complete the hess lancaster test
    // first, if there are at least 4 points, we draw the line from the point 0 to the point 3
    if (actualPoint >= 3) {
      lines.push(
        <Line
          points={[
            base + eyePos[0].x * ratio,
            base - eyePos[0].y * ratio,
            base + eyePos[2].x * ratio,
            base - eyePos[2].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    }

    // then, if there are at least 6 points, we draw the line from the point 0 to the point 5
    if (actualPoint >= 5) {
      lines.push(
        <Line
          points={[
            base + eyePos[0].x * ratio,
            base - eyePos[0].y * ratio,
            base + eyePos[4].x * ratio,
            base - eyePos[4].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    }

    // then, if there are at least 8 points, we draw the line from the point 0 to the point 7
    if (actualPoint >= 7) {
      lines.push(
        <Line
          points={[
            base + eyePos[0].x * ratio,
            base - eyePos[0].y * ratio,
            base + eyePos[6].x * ratio,
            base - eyePos[6].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    }

    // finally, if there are at least 9 points, we draw the line from the point 8 to the point 1
    if (actualPoint >= 9) {
      lines.push(
        <Line
          points={[
            base + eyePos[8].x * ratio,
            base - eyePos[8].y * ratio,
            base + eyePos[1].x * ratio,
            base - eyePos[1].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
      lines.push(
        <Line
          points={[
            base + eyePos[0].x * ratio,
            base - eyePos[0].y * ratio,
            base + eyePos[8].x * ratio,
            base - eyePos[8].y * ratio,
          ]}
          stroke={base_color}
          strokeWidth={base_width}
        />
      );
    }

    for (let i = 0; i < actualPoint; i++) {
      if (actualStimulusType === "punctual") {
        lines.push(
          <Circle
            x={base + eyePos[i].x * ratio}
            y={base - eyePos[i].y * ratio}
            radius={5}
            fill={main_color}
          />
        );
      }
      if (actualStimulusType === "vertical") {
        const actualDegree = 90 + Math.round(eyeRot[i].z);
        const newXOrigin = (20 * Math.cos(actualDegree * Math.PI / 180));
        const newYOrigin = (20 * Math.sin(actualDegree * Math.PI / 180));

        lines.push(
          <Line
            points={[
              newXOrigin + (base + eyePos[i].x * ratio),
              - newYOrigin + (base - eyePos[i].y * ratio),
              - newXOrigin + (base + eyePos[i].x * ratio),
              newYOrigin + (base - eyePos[i].y * ratio),
            ]}
            stroke={main_color}
            strokeWidth={base_width + 3}
          />
        );
      }
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderLeftHessLancasterText = (isLeft) => {
    if (hessLancasterStatus?.actual_user !== uid) {
      return null;
    }

    if (!isShowingValues) {
      return null;
    }
    const ratio = 333.33;
    const base = 226;

    // check if hessLancasterStatus is null, if so, return null
    if (!hessLancasterStatus) {
      return null;
    }
    // from response.data.left_eye_pos, get the number of values of the array
    // if isLeft is true, get the left eye values, otherwise get the right eye values
    const actualStimulusType = hessLancasterStatus?.status_hess_lancaster_stimulus_type || "punctual";
    let eyePosAux = [];
    let eyeRotAux = [];
    let actualPointAux = 0;
    if (actualStimulusType === "vertical") {
      eyePosAux = isLeft ? hessLancasterStatus?.left_eye_pos_vertical : hessLancasterStatus?.right_eye_pos_vertical;
      eyeRotAux = isLeft ? hessLancasterStatus?.left_eye_rot_vertical : hessLancasterStatus?.right_eye_rot_vertical;
      actualPointAux = isLeft ? hessLancasterStatus?.actual_point_left_vertical : hessLancasterStatus?.actual_point_right_vertical;
    }
    if (actualStimulusType === "punctual") {
      eyePosAux = isLeft ? hessLancasterStatus?.left_eye_pos_punctual : hessLancasterStatus?.right_eye_pos_punctual;
      eyeRotAux = isLeft ? hessLancasterStatus?.left_eye_rot_punctual : hessLancasterStatus?.right_eye_rot_punctual;
      actualPointAux = isLeft ? hessLancasterStatus?.actual_point_left_punctual : hessLancasterStatus?.actual_point_right_punctual;
    }

    const eyePos = eyePosAux;
    const eyeRot = eyeRotAux;
    const actualPoint = actualPointAux;


    if (!eyePos) {
      return null;
    }

    // Create the first line with the first two points, the values are in the array where origin is 0 and end is 1 and the format is [x, y]
    // so we have to check if at least the first two points exist
    if (actualPoint < 1) {
      return null;
    }

    // compare the original position with the value of the eyePos.
    const originalXPos = [226, 121, 226, 331, 331, 331, 226, 121, 121];
    const originalYPos = [226, 331, 331, 331, 226, 121, 121, 121, 226];

    // loop to check the values
    const texts = [];
    for (let i = 0; i < actualPoint; i++) {
      const actualXPoint = base + eyePos[i].x * ratio;
      const difXPoint = Math.abs(Math.round((actualXPoint - originalXPos[i]) / 4));

      const actualYPoint = base + eyePos[i].y * ratio;
      const difYPoint = Math.abs(Math.round((actualYPoint - originalYPos[i]) / 4));

      texts.push(
        <Text
          x={base + eyePos[i].x * ratio + 10}
          y={base - eyePos[i].y * ratio + 10}
          text={"x: " + difXPoint + "Δ"}
          fontSize={15}
          fill="black"
          fontFamily="system-ui"
          fontStyle="bold"
        />
      );
      texts.push(
        <Text
          x={base + eyePos[i].x * ratio + 10}
          y={base - eyePos[i].y * ratio + 25}
          text={"y: " + difYPoint + "Δ"}
          fontSize={15}
          fill="black"
          fontFamily="system-ui"
          fontStyle="bold"
        />
      );

      if (actualStimulusType === "vertical") {
        const actualDegree = 180 - Math.abs(180 - Math.round(eyeRot[i].z));
        texts.push(
          <Text
            x={base + eyePos[i].x * ratio + 10}
            y={base - eyePos[i].y * ratio + 40}
            text={actualDegree + "°"}
            fontSize={15}
            fill="black"
            fontFamily="system-ui"
            fontStyle="bold"
          />
        );
      }
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return texts.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };


  const handleSnackbar = () => {
    setSnackbarMessage(t('generalTestLocale.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const allFieldsValid = () => {
    return eye !== "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: test_type,
      config_eye: eye,
      //config_hess_lancaster_stimulus_type: stimulusType,
      config_user_id: uid,
    };
    formData[stimulus_name] = stimulusType;
    const userId = currentUser.uid;

    // esto es para resetear el status si el usuario cambia
    if (formData.config_user_id !== hessLancasterStatus?.actual_user) {
      // escribimos, esta vez, directamente en realtime actualizando el status
      const db = database;
      const dbRef = ref(db, `hess_lancaster_status/${userId}`);
      update(dbRef, {
        actual_user: formData.config_user_id,
        actual_point_left_punctual: 0,
        actual_point_right_punctual: 0,
        actual_point_left_vertical: 0,
        actual_point_right_vertical: 0
      });
    }

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {title}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('generalTestLocale.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('generalTestLocale.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('generalTestLocale.right')}</MenuItem>
                <MenuItem value="left">{t('generalTestLocale.left')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('mainGridScreenLocale.stimulusType')}
              </InputLabel>
              <Select
                label={t('mainGridScreenLocale.stimulusType')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusType}
                onChange={(e) => setStimulusType(e.target.value)}
              >
                <MenuItem value="punctual">{t('mainGridScreenLocale.point')}</MenuItem>
                <MenuItem value="vertical">{t('mainGridScreenLocale.vertical')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('formsLocale.submit')}
          </Button>
        </Box>

        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item lg={12} xl={6} >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" gutterBottom>
                  {t('mainGridScreenLocale.leftEye')}
                </Typography>
                <div style={{ position: 'relative' }}>
                  {/* Texto a la izquierda de la Box */}
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '0%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.superiorRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.lateralRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '100%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.inferiorRectus')}
                    </pre>
                  </Typography>
                  <Box
                    width={451}
                    height={451}
                    style={{
                      backgroundSize: "15px 15px",
                      backgroundImage: "linear-gradient(to right, grey 1px, transparent 1px),linear-gradient(to bottom, grey 1px, transparent 1px)"
                    }}
                  >
                    <Stage width={451} height={451}>
                      <Layer>
                        {renderMainDots()}
                        {renderLeftHessLancasterLines(true)}
                        {renderLeftHessLancasterText(true)}
                      </Layer>
                    </Stage>
                  </Box>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '0%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.inferiorOblique')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.medialRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '100%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.superiorOblique')}
                    </pre>
                  </Typography>
                </div>
              </Box>
            </Grid>

            {/* Right Column */}
            <Grid item lg={12} xl={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >

                <Typography variant="h6" gutterBottom>
                  {t('mainGridScreenLocale.rightEye')}
                </Typography>
                <div style={{ position: 'relative' }}>
                  {/* Texto a la izquierda de la Box */}
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '0%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.inferiorOblique')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.medialRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      left: -80,
                      top: '100%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.superiorOblique')}
                    </pre>
                  </Typography>
                  <Box
                    width={451}
                    height={451}
                    style={{
                      backgroundSize: "15px 15px",
                      backgroundImage: "linear-gradient(to right, grey 1px, transparent 1px),linear-gradient(to bottom, grey 1px, transparent 1px)"
                    }}
                  >
                    <Stage width={451} height={451}>
                      <Layer>
                        {renderMainDots()}
                        {renderLeftHessLancasterLines(false)}
                        {renderLeftHessLancasterText(false)}
                      </Layer>
                    </Stage>
                  </Box>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '0%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.superiorRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.lateralRectus')}
                    </pre>
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      position: 'absolute',
                      textAlign: 'center',
                      right: -80,
                      top: '100%',
                      transform: 'translateY(-50%)',
                      fontSize: "14px"
                    }}
                  >
                    <pre>
                      {t('mainGridScreenLocale.inferiorRectus')}
                    </pre>
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          {/* Button to show or hide the values. When is clicked, change isShowingValues. Besides change variant between contained and outlined */}
          <Button
            variant={isShowingValues ? "outlined" : "contained"}
            color="primary"
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
            onClick={() => setIsShowingValues(!isShowingValues)}
          >
            {/* if isShowingValues is true, show the text "Hide Values", otherwise show the text "Show Values" */}
            {isShowingValues ? t('mainGridScreenLocale.hideValues') : t('mainGridScreenLocale.showValues')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>Ojo:</strong> elegir ojo a medir.
            <ul>
              <li><em>Derecho:</em> evaluar la función oculomotora del ojo derecho en función de la visión binocular, con ojo izquierdo como fijador.</li>
              <li><em>Izquierdo:</em> evaluar la función oculomotora del ojo izquierdo en función de la visión binocular, con ojo derecho como fijador.</li>
            </ul>
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>Tipo de estímulo:</strong>
            <ul>
              <li><em>Punto:</em> el estímulo que aparecerá en cada ojo será un punto. Útil para medir desviaciones verticales y horizontales.</li>
              <li><em>Vertical:</em> el estímulo que aparecerá en cada ojo será una línea vertical. Útil para medir desviaciones verticales, horizontales y ciclodesviaciones.</li>
            </ul>
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            Por favor, sitúe la línea/el punto de color blanco sobre el de color verde. Una vez superpuestos ambos, mantenga el botón <strong>trigger/gatillo</strong> presionado durante 3 segundos.
            <br /><br />
            <strong>Controles:</strong> Los botones, así como la imagen, hacen referencia al controlador derecho.
            <ul>
              <li><em>Botón A:</em> Repetir la última marca</li>
              <li><em>Botón B:</em> Volver al menú principal</li>
              <li><em>Botón trigger/gatillo:</em> Marcar los estímulos</li>
            </ul>
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>Controlador derecho</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MainGridScreen;

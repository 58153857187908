import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
} from "@mui/material";

import { useTranslation } from 'react-i18next';

export default function AddPatientForm({ onSubmit }) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState(""); // Changed from description to surname
  const [birthDate, setBirthDate] = useState(""); // Changed from age to birthDate
  
  const { t } = useTranslation();
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, surname, birthDate }); // Use surname and birthDate in the submission
    setName("");
    setSurname(""); // Reset surname
    setBirthDate(""); // Reset birthDate
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t('addPatient')}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('surname')} // Changed label to Surname
            value={surname} // Bind to surname state
            onChange={(e) => setSurname(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('birthDate')} // Changed label to Birth Date
            type="date" // Change type to date for birth date selection
            value={birthDate} // Bind to birthDate state
            onChange={(e) => setBirthDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true, // Ensure the label shrinks to accommodate the date input
            }}
          />
          <Box sx={{ textAlignLast: "center" }}>
            <Button type="submit" variant="contained" color="primary">
              {t('formsLocale.submit')}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoImage from "../assets/logo_dicopt.png";

const PatientDrawerComponent = ({
  anchor,
  handleOpenPopup
}) => {
  
  const { t } = useTranslation();

  const { uid } = useParams();

  return (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <Link to="/dashboard">
        <img
          src={LogoImage}
          alt="Logo"
          style={{ height: "40px", padding: 20, cursor: "pointer" }} // Add cursor style for better UX
        />
      </Link>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Button
          startIcon={<HomeIcon />}
          component={Link}
          to={`/patient/${uid}`}
          
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('patientOverview')}
        </Button>
      </Box>
      <Divider />

      {/* BLOQUE DE CAMPIMETRIA */}
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('campimetryTestLocale.campimetry')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/campimetria`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

        <Button
          startIcon={
            <DownloadIcon
              style={{ color: "#041e49"}}
            />
          }
          onClick={handleOpenPopup}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49", // This will change the text color; adjust if necessary
            width: "100%",
          }}
        >
          {t('pdfLocale.downloadPDF')}
        </Button>
      </Box>
      <Divider />
      
      {/* BLOQUE DE HESS LANCASTER */}
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('hessLancasterTestLocale.hessLancaster')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/hesslancaster`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>
      </Box>
      <Divider />

      {/* BLOQUE DE WEISS 
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('weissTestLocale.weiss')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/weiss`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      */}

      {/* BLOQUE DE E SNELLEN 
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('eSnellenTestLocale.eSnellen')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/esnellen`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      */}
      {/* BLOQUE DE AMSLER GRID */}
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('amslerGridTestLocale.amslerGrid')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/amslergrid`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      
      {/* BLOQUE DE CONTRAST SENSITIVITY 
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('contrastSensitivityTestLocale.contrastSensitivity')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/contrastsensitivity`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      */}
      {/* BLOQUE DE DYNAMIC VA 
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('dynamicVATestLocale.dynamicVA')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/dynamicvisualacuity`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      */}
      {/* BLOQUE DE TEST DE WORTH */}
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Typography variant="h7" sx={{ color: "#041e49", marginBottom: 2 }}>
          {t('worthTestLocale.worth')}
        </Typography>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/worthtest`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('generalTestLocale.takeTest')}
        </Button>

      </Box>
      <Divider />
      
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          component={Link}
          to={"/dashboard"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('exit')}
        </Button>
      </Box>
    </Box>
  );
};

export default PatientDrawerComponent;

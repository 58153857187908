import {
  Box,
  Button,
  CardActionArea
} from "@mui/material";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import CampimetriaImage from "../../assets/mainContentImages/Campimetria.jpg";
import HessLancasterImage from "../../assets/mainContentImages/HessLancaster.jpg";
import SnellenImage from "../../assets/mainContentImages/Snellen.jpg";
import RejillaAmslerImage from "../../assets/mainContentImages/RejillaAmsler.jpg";
import SensibilidadContrasteImage from "../../assets/mainContentImages/SensibilidadContraste.jpg";
import AVDinamicaImage from "../../assets/mainContentImages/AVDinamica.jpg";
import WorthImage from "../../assets/mainContentImages/Worth.jpg";

export default function MainContent() {
  const { uid } = useParams();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardActionArea
              component={Link}
              to={`/patient/${uid}/campimetria`}
            >
              <CardMedia
                sx={{ height: 200 }}
                image={CampimetriaImage}
                title="campimetry"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('campimetryTestLocale.campimetry')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                La campimetría es un procedimiento para evaluar el rango completo de visión de un individuo, hasta 120 grados. Con la campimetría de Dicopt Pro podemos evaluar, además del rango de visión, el tiempo de reacción y la sensibilidad de cada punto de la retina medida en decibelios.
                <br />
                <br />
                Especialmente utilizada para detección y seguimiento de pacientes con patologías del campo visual, como glaucoma o degeneración macular, o en pacientes con daño cerebral.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/campimetria`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardActionArea
              component={Link}
              to={`/patient/${uid}/hesslancaster`}
            >
              <CardMedia
                sx={{ height: 200 }}
                image={HessLancasterImage}
                title="hess lancaster"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('hessLancasterTestLocale.hessLancaster')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                La pantalla de Hess-Lancaster es  una prueba que sirve para evaluar los movimientos oculares, comparando la posición de un ojo respecto al otro en ciertas posiciones de mirada. En este test el ojo fijador verá unos puntos o líneas sobre una cuadrícula y el paciente debe colocar sobre estos estímulos visuales un punto o línea que verá con el otro ojo, el examinado.
                Se realiza a 90 cm y cada cuadrado supone 5Δ de desviación.
                Es imprescindible su uso en pacientes con diplopia o visión doble, ya que podremos ver parálisis o paresias y conocer los músculos implicados.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/hesslancaster`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/*
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardMedia
              sx={{ height: 200 }}
              image={HessLancasterImage}
              title="weiss"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('weissTestLocale.weiss')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                La pantalla de Weiss es una variación de la pantalla de Hess-Lancaster. Ahora la cuadrícula solo será vista por el ojo fijador, no por el ojo que evaluamos, de modo que habrá una disociación mayor. El mecanismo de la prueba es el mismo que en la “Pantalla de Hess-Lancaster”.
                Se evaluarán 9 posiciones de mirada a una distancia de 90cm y cada cuadrado simboliza 5Δ de desviación.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/weiss`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        */}
        {/*
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardActionArea
              component={Link}
              to={`/patient/${uid}/esnellen`}
            >
              <CardMedia
                sx={{ height: 200 }}
                image={SnellenImage}
                title="weiss"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('eSnellenTestLocale.eSnellen')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Esta prueba mide el umbral de discrimación visual de un usuario; es decir, aporta información sobre la capacidad de un sujeto para ver y entender objetos en su campo visual. Es una medida imprescindible sobre la integridad funcional del sistema visual. El resultado podrá verse en la escala que queramos.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/esnellen`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        */}
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardActionArea
              component={Link}
              to={`/patient/${uid}/amslergrid`}
            >
              <CardMedia
                sx={{ height: 200 }}
                image={RejillaAmslerImage}
                title="amslerGrid"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('amslerGridTestLocale.amslerGrid')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                La rejilla de Amsler consiste en una cuadrícula en cuyo centro hay un círculo. Sirve para hallar problemas de la visión, normalmente patológicos, en los 20º centrales de la retina.
                Es una prueba fundamental para hacer seguimiento de patologías, especialmente la DMAE. En Dicopt Pro hay 3 tipos de rejilla de Amsler y podremos incluir una ayuda en caso de escotoma central de la visión.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/amslergrid`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/*
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
              <CardActionArea
              component={Link}
              to={`/patient/${uid}/contrastsensitivity`}
            >
            <CardMedia
              sx={{ height: 200 }}
              image={SensibilidadContrasteImage}
              title="contrastSensitivity"
            />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('contrastSensitivityTestLocale.contrastSensitivity')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                El test de sensibilidad al contraste mide la capacidad del ojo umbral de detección de diferencias en contraste, complementando la evaluación de la agudeza visual. Mientras que la agudeza visual determina la capacidad de ver detalles finos a alto contraste, el test de sensibilidad al contraste evalúa cuán bien se pueden distinguir objetos cuando hay poca diferencia de brillo con el fondo, proporcionando una imagen más completa de la función visual.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/contrastsensitivity`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        */}
        {/*
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
              <CardActionArea
              component={Link}
              to={`/patient/${uid}/dynamicvisualacuity`}
            >
            <CardMedia
              sx={{ height: 200 }}
              image={AVDinamicaImage}
              title="dynamicVisualAcuity"
            />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('dynamicVATestLocale.dynamicVA')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                El test de sensibilidad al contraste mide la capacidad del ojo umbral de detección de diferencias en contraste, complementando la evaluación de la agudeza visual. Mientras que la agudeza visual determina la capacidad de ver detalles finos a alto contraste, el test de sensibilidad al contraste evalúa cuán bien se pueden distinguir objetos cuando hay poca diferencia de brillo con el fondo, proporcionando una imagen más completa de la función visual.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/dynamicvisualacuity`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        */}
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ maxWidth: 500 }}
            style={{
              margin: '20px', // Puedes ajustar este valor según sea necesario
            }}>
            <CardActionArea
              component={Link}
              to={`/patient/${uid}/worthtest`}
            >
              <CardMedia
                sx={{ height: 200 }}
                image={WorthImage}
                title="worthTest"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t('worthTestLocale.worth')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                El test de Worth es una prueba diseñada para evaluar la visión binocular de un paciente. Permite detectar supresión, anomalías de fusión y desviaciones que provoquen diplopía en alguna distancia de mirada. Este test permite determinar si ambos ojos están trabajando juntos de manera efectiva.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"
                component={Link}
                to={`/patient/${uid}/worthtest`}
              >
                {t('generalTestLocale.takeTest')}
              </Button>
            </CardActions>
          </Card>
        </Grid>


      </Grid>
    </Box>
  )
}
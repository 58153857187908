import React from "react";
import { useTranslation } from 'react-i18next';

import MainGridScreen from "./MainGridScreen";

const HessLancasterForm = () => {
  const { t } = useTranslation();
  
  // Inside your component's return statement
  return (
    <MainGridScreen
      title={t('hessLancasterTestLocale.hessLancaster')}
      server_route="hess-lancaster-status"
      test_type="hess_lancaster"
      stimulus_name="config_hess_lancaster_stimulus_type"
    />
  );
};

export default HessLancasterForm;
